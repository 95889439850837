import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const CookieBeleidPage = () => (
  <Layout>
    <SEO title="Cookiebeleid" />
    <h1>Cookiebeleid</h1>
    <p>
      Cookiebeleid van Yves De Vrient Yves De Vrient met maatschappelijke zetel
      te Kruishoutemsesteenweg 259, 9750 Kruisem is verantwoordelijk voor dit
      cookiebeleid. Voor alle vragen en/of opmerkingen kunt u terecht op het
      hierboven vermelde adres of bij op het e-mailadres{' '}
      <a
        rel="noopener noreferrer"
        style={{
          fontSize: `0.9rem`,
          lineHeight: `1.75rem`,
          color: `#0a67b7`,
        }}
        href="mailto:yves@yvesdevrient.be"
      >
        yves@yvesdevrient.be
      </a>
      .<br />
      <br />
      Yves De Vrient vindt het belangrijk dat u op iedere plaats en elk ogenblik
      haar Yves De Vrient -content kan bekijken, beluisteren, lezen of beleven
      via diverse mediaplatformen. Yves De Vrient wil ook werken aan
      interactieve diensten en diensten op uw maat. Op Yves De Vrient
      -onlinediensten worden technieken gehanteerd om dit mogelijk te maken,
      bijvoorbeeld met behulp van cookies en scripts. Deze technieken worden
      hierna gemakelijkheidshalve cookies genoemd. In dit cookiebeleid wenst
      Yves De Vrient u te informeren welke cookies worden gebruikt en waarom dit
      gebeurt. Verder wordt toegelicht in welke mate u als gebruiker het gebruik
      kan controleren. Yves De Vrient wil namelijk graag uw privacy en de
      gebruiksvriendelijkheid van haar onlinediensten zoveel mogelijk
      waarborgen. Yves De Vrient heeft geprobeerd dit beleid zo eenvoudig
      mogelijk te houden.
      <br />
      <br /> Dit cookiebeleid is van toepassing op alle “Yves De
      Vrient-onlinediensten”, met name alle websites, (mobiele) applicaties en
      internetdiensten die Yves De Vrient aanbiedt en die toegang geven tot Yves
      De Vrient content.
      <br />
      <br />
      Bepaalde verzamelde gegevens laten toe om u te identificeren of om een
      band met u te leggen als natuurlijke persoon en kwalificeren derhalve als
      persoonsgegevens. In dat geval is ook de informatie (m.i.v. uw
      verschillende rechten) zoals uiteengezet in ons privacybeleid [link] van
      toepassing op deze verwerkingen door de cookies. Yves De Vrient kan het
      cookiebeleid op elk moment veranderen. Dat kan bijvoorbeeld gebeuren in
      het kader van veranderingen aan haar diensten of de geldende wetgeving.
      Het gewijzigde beleid wordt dan bekendgemaakt op de relevante Yves De
      Vrient-onlinediensten en gelden vanaf het moment dat deze bekendgemaakt
      wordt.
      <br />
      <br />
    </p>
    <h2> Wat zijn cookies precies?</h2>
    <p>
      {' '}
      Cookies zijn kleine gegevens- of tekstbestanden die op uw computer of
      mobiele apparaat zijn geïnstalleerd wanneer u een website bezoekt of een
      (mobiele) toepassing gebruikt. Het cookiebestand bevat een unieke code
      waarmee uw browser herkend kan worden door de website of webtoepassing
      tijdens het bezoek aan de online service of tijdens opeenvolgende,
      herhaalde bezoeken. Cookies kunnen worden geplaatst door de server van de
      website of applicatie die u bezoekt, maar ook door servers van derden die
      al dan niet met deze website of applicatie samenwerken. Cookies maken over
      het algemeen de interactie tussen de bezoeker en de website of applicatie
      gemakkelijker en sneller en helpen de bezoeker om te navigeren tussen de
      verschillende delen van een website of applicatie. Hoe kan u het gebruik
      van cookies op Yves De Vrient-onlinediensten weigeren of beheren? U kunt
      de installatie van cookies weigeren via uw browserinstellingen. U kunt op
      elk gewenst moment ook de reeds geïnstalleerde cookies van uw computer of
      mobiele apparaat verwijderen, als volgt:{' '}
    </p>
    <ul style={{ marginLeft: `1rem`, marginTop: `1rem`, marginBottom: `1rem` }}>
      <li>
        Internet Explorer:
        <a
          href="https://support.microsoft.com/nl-be/help/17479/windows-internet-explorer-11-change-security-privacy-settings"
          rel="noopener noreferrer"
          style={{
            fontSize: `0.9rem`,
            lineHeight: `1.75rem`,
            color: `#0a67b7`,
          }}
        >
          {' '}
          https://support.microsoft.com/nl-be/help/17479/windows-internet-explorer-11-change-security-privacy-settings
        </a>
      </li>
      <li>
        Chrome:{' '}
        <a
          href="https://support.google.com/chrome/bin/answer.py?hl=nl&answer=95647
        "
          rel="noopener noreferrer"
          style={{
            fontSize: `0.9rem`,
            lineHeight: `1.75rem`,
            color: `#0a67b7`,
          }}
        >
          {' '}
          https://support.google.com/chrome/bin/answer.py?hl=nl&answer=95647
        </a>
      </li>
      <li>
        Firefox:
        <a
          href="      http://support.mozilla.org/nl/kb/cookies-in-en-uitschakelen-websites-voorkeuren?redirectlocale=nl&redirectslug=Cookies+in-+en+uitschakelen

        "
          rel="noopener noreferrer"
          style={{
            fontSize: `0.9rem`,
            lineHeight: `1.75rem`,
            color: `#0a67b7`,
          }}
        >
          {' '}
          https://support.mozilla.org/nl/kb/cookies-in-en-uitschakelen-websites-voorkeuren?redirectlocale=nl&redirectslug=Cookies+in-+en+uitschakelen
        </a>
      </li>
      <li>
        Safari:
        <a
          href="      https://support.apple.com/kb/PH5042
        "
          rel="noopener noreferrer"
          style={{
            fontSize: `0.9rem`,
            lineHeight: `1.75rem`,
            color: `#0a67b7`,
          }}
        >
          {' '}
          https://support.apple.com/kb/PH5042{' '}
        </a>
      </li>
    </ul>
    <p>
      {' '}
      Wanneer u cookies uitschakelt, moet u er rekening mee houden dat bepaalde
      grafische elementen er misschien niet mooi uitzien, of dat u bepaalde
      toepassingen niet kunt gebruiken. Hieronder vindt u een gedetailleerde
      lijst van alle soorten cookies die Yves De Vrient op zijn websites
      gebruikt.
    </p>
    <h2 style={{ marginTop: `1rem` }}>Gebruikte cookies</h2>
    <h3 style={{ fontWeight: `600`, marginBottom: `0.5rem` }}>
      Meten van gebruikersbezoek
    </h3>{' '}
    <ul style={{ marginLeft: `1rem`, marginTop: `1rem`, marginBottom: `1rem` }}>
      <li style={{ margin: `0.6rem 0` }}>
        <p>
          {' '}
          <b>Namen van cookies: </b> _ga, _gid, _gat en _umtz, _umta en
          gaClientId
        </p>
      </li>
      <li style={{ margin: `0.9rem 0` }}>
        <p>
          <b>Doel:</b> Het gebruikersbezoek op yvesdevrient.be wordt via Google
          Universal Analytics op geanonimiseerde wijze gemeten om het
          informatieaanbod op yvesdevrient.be te optimaliseren. De global header
          en footer zijn de horizontale balken die u boven- en onderaan deze
          website vindt. Google Analytics is zo ingesteld dat het IP-adres niet
          wordt verwerkt, noch gegevens worden gedeeld met Google of andere
          derde partijen.
        </p>
      </li>
      <li style={{ margin: `0.9rem 0` }}>
        <b> Website:</b> www.yvesdevrient.be
      </li>
      <li style={{ margin: `0.9rem 0` }}>
        <p>
          <b>Cookie(s) geplaatst door: </b>Het Google analytics object om zelf
          ClientID's aan te maken.
        </p>
      </li>
      <li style={{ margin: `0.9rem 0` }}>
        <b>Ontvanger(s) van de gegevens: </b> Google
      </li>
      <li style={{ margin: `0.9rem 0` }}>
        {' '}
        <p>
          <b>Geldigheid: </b>permanente cookies met een geldigheid van maximaal
          2 jaar
        </p>
      </li>
    </ul>
  </Layout>
)

export default CookieBeleidPage
